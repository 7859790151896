import Topbar from "../Topbar/Topbar";
import Footerbar
    from "../Footerbar/Footerbar";
import React, { useEffect, useState } from "react";






const Contact = () => {


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Faites quelque chose avec les donnÃ©es du formulaire, par exemple, les envoyer Ã  un backend
        console.log(formData);
    };



    return (
        <div className="flex flex-col min-h-screen">
            <Topbar />

            <div className="bg-white">
                <div className="  sm:px-6  lg:px-8">
                    <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-2xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                        <svg
                            viewBox="0 0 1024 1024"
                            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                            aria-hidden="true"
                        >
                            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                            <defs>
                                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                    <stop stopColor="#7775D6" />
                                    <stop offset={1} stopColor="#E935C1" />
                                </radialGradient>
                            </defs>
                        </svg>
                        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                Join Us
                                <br />

                            </h2>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                Whether you graduated recently or years ago, the EducAid Alumni Network welcomes you to be a part of this thriving network.
                                By joining us, you gain access to a network of like-minded individuals who share a common educational background and a passion
                                for making a difference.

                            </p>


                        </div>
                        <div className="relative mt-16 h-80 lg:mt-8">
                            <img
                                className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md filter brightness-75 saturate-125 bg-white/5 ring-1 ring-white/10 opacity-50"
                                src="img/contact.gif"
                                alt="App screenshot"
                                width={1824}
                                height={1080}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-grow py-20">
                <div className="container mx-auto flex flex-col md:flex-row">
                    <div className="md:w-1/2 flex justify-center items-center px-5">
                        <div className="ms-5 p-5 border border-gray-300">
                            <h1 className="text-4xl my-4 text-left">Contact-us</h1>
                            <p className="text-gray-500 text-left">
                                We're here to answer all your questions and discuss how we can assist you.
                                Feel free to reach out to us using the form below or using the provided contact information.

                            </p>

                            <form onSubmit={handleSubmit} className="mt-4">
                                <input
                                    className="w-full mb-4 p-2 border border-gray-300"
                                    type="text"
                                    placeholder="Nom"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <input
                                    className="w-full mb-4 p-2 border border-gray-300"
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <textarea
                                    className="w-full mb-4 p-2 border border-gray-300"
                                    placeholder="Message"
                                    rows={4}
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                ></textarea>
                                <button
                                    className="bg-blue-500 text-white p-2"
                                    type="submit"
                                >
                                    Envoyer
                                </button>
                            </form>
                        </div>
                    </div>

                    <div className="md:w-1/2 px-4 pt-5 flex flex-col items-start md:items-start">


                        <p className="font-serif mt-5 mb-2">OUR OFFICE</p>
                        <p className="text-gray-500 font-serif">23 Sheriff Drive ,</p>
                        <p className="text-gray-500 font-serif">Lumley,Freetown</p>
                        <p className="text-gray-500 font-serif">Sierra Leone , West Africa</p>


                        <p className="font-serif mt-5 mb-2">WORKING HOURS</p>
                        <p className="text-gray-500 font-serif">8AM - 5PM, Mon to Fri</p>

                        <p className="font-serif mt-5 mb-2">CONTACT</p>
                        <p className="text-gray-500 font-serif">   Website: https://educaidalumninetwork.com/</p>
                        <p className="text-gray-500 font-serif">Email: educaidalumninetwork@gmail.com</p>
                        <p className="text-gray-500 font-serif"> Phone number: +232 88 107524</p>
                        <p className="text-gray-500 font-serif">Facebook: https://www.facebook.com/EducAid.Alumni?mibextid=LQQJ4d</p>





                    </div>
                </div>

                <div className="container mt-5 mx-auto">
                    <h1 className="font-bold text-3xl my-4">Emplacement</h1>
                    <div className="flex">
                        <span className="font-bold">adresse : 23 Sheriff Drive Lumley, Freetown, Sierra Leone ,West Africa </span>
                        <span className="font-bold mx-5">WORKING HOURS : 8AM - 5PM, Mon to Fri </span>
                    </div>
                    <img
                        className="my-5 w-full"
                        src="img/localisation.png"
                        alt="bg"
                    />
                </div>
            </div>

            <Footerbar />
        </div>

    )
}

export default Contact;