import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Navbar } from 'flowbite-react';
import { NavLink } from 'react-router-dom';


const Topbar = () => {
    const location = useLocation().pathname;


    return (
            <Navbar fluid rounded className="fixed top-0 w-full bg-white shadow-lg z-10">
                <Navbar.Brand href="#">
                    <img src="img/logo.png" className="mr-3 h-8 md:h-16" alt="Flowbite React Logo" />
                    <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">EducAid Alumni Network</span>
                </Navbar.Brand>
                <div className="flex md:order-2">

                    <Navbar.Toggle />
                </div>
                <Navbar.Collapse>
                        {/* <NavLink to="/home" active={location === '/home' || '' ? true : false} >Home</NavLink>                
                        <NavLink to="/about" active={location === '/about' ? true : false} >About Us</NavLink>
                        <NavLink to="/team" active={location === '/team' ? true : false} >Our Team</NavLink>
                        <NavLink to="/galery" active={location === '/galery' ? true : false} >Galery</NavLink>
                        <NavLink to="/contact" active={location === '/contact' ? true : false} >Contact</NavLink> */}

                      <Navbar.Link href="home" active={location === '/home' || '' ? true : false} >
                        Home
                    </Navbar.Link>
                    <Navbar.Link href="about" active={location === '/about' ? true : false} >About Us</Navbar.Link>
                    <Navbar.Link href="team" active={location === '/team' ? true : false} >Our Team</Navbar.Link>
                    <Navbar.Link href="galery" active={location === '/galery' ? true : false} >Gallery</Navbar.Link>
                    <Navbar.Link href="contact" active={location === '/contact' ? true : false} >Contact</Navbar.Link> 

                </Navbar.Collapse>
            </Navbar>



    );
}

export default Topbar;

