import { Footer } from 'flowbite-react';
import { BsDribbble, BsFacebook, BsGithub, BsInstagram, BsTwitter } from 'react-icons/bs';


const Footerbar = () => {
    return (
        <Footer container >

            <div className="w-full  bg-gray-100 ">
                <Footer.Divider />
                <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1 mt-8">
                    <div className=' '>
                        {/* <div className='ml-60'>EducAid Alumni Network</div> */}
                        <Footer.Title className='md:ml-20' title="EducAid Alumni Network" />
                        <div className="flex justify-center items-center h-full">
                            <img src="img/logo.png" className="h-40 md:ml-20 md:h-80" alt="Flowbite React Logo" />
                        </div>

                        {/*      <Footer.Brand
                            href="/home"
                            src="img/logo.png"
                            alt="Logo"
                            name="EducAid Alumni Network"
                            imageClassName="h-96  !important"

                        /> */}

                    </div>
                    <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
                        <div>
                            <Footer.Title title="Donate" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="#">SWIFT code : UTBSSLFRXXX</Footer.Link>
                                <Footer.Link href="#">Branch name : UNION TRUST BANK LIMITED</Footer.Link>
                                <Footer.Link href="#">Branch address : LIGHTFOOT BOSTON STREET</Footer.Link>
                                <Footer.Link href="#">City : FREETOWN</Footer.Link>
                                <Footer.Link href="#">Country : Sierra Leone</Footer.Link>
                                <Footer.Link href="#">Bank name : UNION TRUST BANK LIMITED </Footer.Link>
                                <Footer.Link href="#">Account name : EDUCAID ALUMNI NETWORK</Footer.Link>
                                <Footer.Link href="#">Account number : 210-122251-01 </Footer.Link>
                                <Footer.Link href="#">Account BBAN : 004001122251120183</Footer.Link>
                                <Footer.Link href="#">Mobile transfers :</Footer.Link>
                                <Footer.Link href="#">• Orange Money: 076 80 20 65</Footer.Link>
                                <Footer.Link href="#">• Afrimoney: 099 80 20 65 </Footer.Link>

                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="Volunteer" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="#">EducAid Summer Schooling</Footer.Link>
                                <Footer.Link href="#">Community service</Footer.Link>
                                <Footer.Link href="#">Employability Skills Workshops</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                        <div>
                            <Footer.Title title="PARTNERS" />
                            <Footer.LinkGroup col>
                                <Footer.Link href="#">EducAid Sierra Leone</Footer.Link>
                                <Footer.Link href="#">https://www.educaid.org.uk/</Footer.Link>
                            </Footer.LinkGroup>
                        </div>
                    </div>
                </div>
                <Footer.Divider />
                <div className="w-full sm:flex sm:items-center sm:justify-between">
                    <Footer.Copyright href="#" by="EAN™" year={2023} />
                    <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
                        <Footer.Icon href="#" icon={BsFacebook} />
                        <Footer.Icon href="#" icon={BsInstagram} />
                        <Footer.Icon href="#" icon={BsTwitter} />
                        <Footer.Icon href="#" icon={BsGithub} />
                        <Footer.Icon href="#" icon={BsDribbble} />
                    </div>
                </div>
            </div>
        </Footer>
    )
}

export default Footerbar;