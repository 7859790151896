import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Galery from './Components/Galery/Galery';
import Contact from './Components/Contact/Contact';
import Team from './Components/Team/Team';



function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About/>} />
        <Route path="/galery" element={<Galery/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/team" element={<Team/>} />

      </Routes>
    </div>  
  );
}

export default App;
