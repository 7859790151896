import React, { useState, useEffect, useRef } from "react";
import Footerbar from "../Footerbar/Footerbar";
import Topbar from "../Topbar/Topbar";
import { Carousel, Card } from 'flowbite-react';
import { AcademicCapIcon, UserGroupIcon } from '@heroicons/react/24/solid'
import { LiaUsersSolid } from "react-icons/lia";
import { useSpring, animated, config } from 'react-spring';
import './Home.css'
import { motion } from 'framer-motion';


const Actualite = () => {
    const articles = [
        {
            id: 1,
            title: 'EDUCAID ALUMNI CONFERENCE',
            content: 'Welcome to the EducAid Alumni Conference – Where Connections Flourish and Ideas Ignite!The EducAid Alumni Network stands as a beacon of collaboration, inspiration, and growth for graduates of EducAid schools. One of our flagship initiatives is the annual EducAid Alumni Conference, a vibrant gathering that brings together alumni from diverse backgrounds and professions under one roof. This conference serves as a catalyst for meaningful interactions, idea exchange, and the strengthening of the bonds that tie our alumni network.',
            date: '2023-11-10',
            image: 'img/img1.avif',
        },
        {
            id: 2,
            title: 'Join the Conversation',
            content: "Whether you are a past attendee or considering your first conference, we invite you to join the conversation. Share your experiences, insights, and aspirations with the EducAid Alumni Network community. Together, let's make each conference a stepping stone for personal and collective success. At the EducAid Alumni Network, we believe in the power of coming together, sharing knowledge, and building a network that supports the continued success of each member. Join us at the next EducAid Alumni Conference and be part of a transformative experience that transcends borders, professions, and generations.",
            date: '2023-11-09',
            image: 'img/img2.avif',
        },
        
        
       /*  {
            id: 3,
            title: 'Upcoming event 1',
            content: 'Pellentesque habitant morbi tristique senectus et netus .',
            date: '2023-11-08',
            image: 'img/img3.avif',
        },
        {
            id: 4,
            title: 'Upcoming event 2',
            content: 'Pellentesque habitant morbi tristique senectus et netus et  ac turpis egestas.',
            date: '2023-11-07',
            image: 'img/idea.avif',
        }, */
        // Ajoutez d'autres articles avec des images selon le besoin
    ];

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setScrollPosition(prevPosition => (prevPosition + 1) % articles.length);
        }, 3000); // Changez la valeur pour ajuster la durée entre chaque transition

        return () => clearInterval(intervalId);
    }, [articles.length]);

    return (
        <div className="news-feed-container  ">
            <div className="container mx-auto  news-feed">
                {articles.map((article, index) => (
                    <Card
                        key={article.id}
                        className="max-w-md mx-5 my-4 flex-1 md:max-w-[100%]" // Ajoutez la classe "flex-1"
                        imgAlt={`Image ${index + 1}`}
                        imgSrc={article.image}
                    >
                        <div className="news-content">
                            <h2 className="text-2xl">{article.title}</h2>
                            <p>{article.content}</p>
                            <p>Date de publication : {article.date}</p>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
};


const AnimatedNumber = ({ value }) => {
    return (
        <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
            className="text-3xl font-bold tracking-tight"
        >
            {value}
        </motion.p>
    );
};


const Home = () => {




    return (
        <div>
            <Topbar />
            <div class="mx-auto   min-h-screen mt-[85px]">
                {/* carousel */}
                <div className="h-56 md:h-[700px] ">
                    <Carousel>
                        <img src="img/img1.avif" alt="..." />
                        <img src="img/img4.jpeg" alt="..." />
                        <img src="img/img2.avif" alt="..." />
                        <img src="img/img3.avif" alt="..." />
                        <img src="img/wassce.jpeg" alt="..." />
                    </Carousel>
                </div>

                {/* services */}
                <div className="container mx-auto flex flex-wrap justify-center md:justify-center my-8">
                    <h5 className="text-5xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Services
                    </h5>
                </div>
                <div className="container mx-auto flex flex-wrap justify-center md:justify-center">
                    <Card href="#" className="max-w-sm m-4">
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <AcademicCapIcon className="h-16 w-16 text-blue-500" />
                            Formation
                        </h5>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                            Elevate your skills with our diverse training programs. Perfect for
                            beginners and seasoned professionals, our hands-on approach ensures growth.
                        </p>
                    </Card>
                    <Card href="#" className="max-w-sm m-4">
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <UserGroupIcon className="h-16 w-16 text-rose-500" />
                            Partners
                        </h5>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                            Proudly collaborating with trusted partners to deliver tailored solutions
                            and tackle business challenges with innovation.                        </p>
                    </Card>
                    <Card href="#" className="max-w-sm m-4">
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <LiaUsersSolid className="h-16 w-16 text-green-500" />
                            Associations
                        </h5>
                        <p className="font-normal text-gray-700 dark:text-gray-400">
                            Join our community for networking, knowledge-sharing, and professional growth.
                            Explore opportunities within our dynamic associations.
                        </p>
                    </Card>
                </div>

                {/* statistique  */}
              {/*   <div className="bg-white py-16 sm:py-32">
                    <div className="container mx-auto max-w-7xl px-6 lg:px-8">
                        <h2 className="text-4xl font-bold text-gray-800 mb-8">Educational Impact</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                            <div className="bg-blue-500 p-6 rounded-lg shadow-md text-white">
                                <h3 className="text-xl font-semibold mb-2">Students Enrolled</h3>
                                <AnimatedNumber value={10000} />
                            </div>
                            <div className="bg-green-500 p-6 rounded-lg shadow-md text-white">
                                <h3 className="text-xl font-semibold mb-2">Courses Offered</h3>
                                <AnimatedNumber value={100} />
                            </div>
                            <div className="bg-purple-500 p-6 rounded-lg shadow-md text-white">
                                <h3 className="text-xl font-semibold mb-2">Graduates Success</h3>

                                <span className="text-xl font-semibold tracking-tight flex"><AnimatedNumber value={90} /> %</span>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* News */}
                <div className=" bg-gray-800 p-6  shadow-md min-h-screen mt-20">
                    <div className="container mx-auto my-8 text-center md:text-left">
                        <h5 className="text-5xl font-bold tracking-tight text-white dark:text-white mb-6">
                            Explore Our Latest Educational Insights
                        </h5>
                        <div className="bg-blue-500 h-1 w-20 mx-auto mb-8"></div>
                        <p className="text-lg mb-6 text-white dark:text-gray-400">
                            Dive into the world of knowledge with our latest educational articles.
                            Whether you're passionate about science, history, technology, or other fields,
                            our collection of educational articles offers enriching perspectives and captivating information.
                            Explore below to broaden your horizons!
                        </p>
                    </div>

                    <div>
                        <Actualite />
                    </div>
                </div>



            </div>
            <Footerbar />
        </div>
    )
}

export default Home;