import Footerbar from "../Footerbar/Footerbar";
import Topbar from "../Topbar/Topbar";
import { Avatar, Blockquote } from 'flowbite-react';

const people = [
    {
        name: 'Ibrahim Bai Bundu',
        role: 'President',
        imageUrl: 'img/presi.jpeg',
    },
    {
        name: 'Gribrilla M Kamara',
        role: 'Vice - president',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Gladys Mansaray',
        role: 'Secretary General',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Lawrence Conteh',
        role: 'P.R.O',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Abubakarr Kargbo',
        role: 'Organising Secretary',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Ibrahim Tholley',
        role: 'Financial Secretary',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Baimba S . Kamara',
        role: 'Treasurer',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Kabiru I. Mansaray',
        role: 'Chief Whip',
        imageUrl: 'img/profile.jpg',
    },
    // More people...
]



const Team = () => {
    return (
        <div>
            <Topbar />

            {/* our mission */}
            <div className="bg-white">
                <div className="  sm:px-6  lg:px-8">
                    <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-2xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                        <svg
                            viewBox="0 0 1024 1024"
                            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                            aria-hidden="true"
                        >
                            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                            <defs>
                                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                    <stop stopColor="#7775D6" />
                                    <stop offset={1} stopColor="#E935C1" />
                                </radialGradient>
                            </defs>
                        </svg>
                        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                Our Mission
                                <br />

                            </h2>
                            {/*   <p className="mt-6 text-lg leading-8 text-gray-300">
                                To enhance and renew the lifelong EducAid experience for all alumni, whoever theyare and wherever
                                they may be. We strive to inspire new ideas, affiliations, friendships, professional fulfilment,
                                and acts of service, around the world.
                            </p> */}
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                At EAN, our mission is to foster a strong and supportive network that empowers EducAid alumni to
                                thrive in their personal and professional lives. We aim to create opportunities for continued
                                learning, professional development, and community engagement. By leveraging the collective strength
                                of our alumni, we aspire to contribute positively to the growth and sustainability of EducAid and
                                its broader impact on education in underserved communities.
                            </p>
                            {/*                          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                <a
                                    href="#"
                                    className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                >
                                    Get started
                                </a>
                                <a href="#" className="text-sm font-semibold leading-6 text-white">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div> */}
                        </div>
                        <div className="relative mt-16 h-80 lg:mt-8">
                            <img
                                className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md filter brightness-75 saturate-125 bg-white/5 ring-1 ring-white/10 opacity-10"
                                src="img/mission.gif"
                                alt="App screenshot"
                                width={1824}
                                height={1080}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Where are we now  */}
            <figure className="mx-auto max-w-screen-xl text-center py-14">
                {/* <h2 className="text-4xl font-bold text-gray-400 mb-8">Where are we now ?!</h2> */}
                <svg
                    className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 14"
                >
                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                </svg>
                <Blockquote>
                    <p className="text-xl font-medium italic text-gray-500 dark:text-white">
                        EAN M & E committee is currently working on a kobo toolbox survey to gather information about the alumni's
                        personal, educational, and career pathways, including their majors in college, employment status, and alumni
                        growth. These data would be used to support EAN members and other organisations/institutions to develop their
                        values focused work and work on employability through EAN Skills Bootcamps. EAN Employability Skills Bootcamps
                        - The network aim to address the soft skill gap its members and other organisations/institutions’ might be facing
                        before starting their career. Through this project, alumni can build their CV and digital portfolio, whilst learning
                        crucial soft skills to improve their employability. Employers may get the opportunity to network with us while accessing
                        fresh ideas and talent.
                    </p>
                </Blockquote>
                <figcaption className="mt-6 flex items-center justify-center space-x-3">
                    <Avatar rounded size="xs" img="img/logo.png" alt="profile picture" />
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <cite className="pr-3 font-medium text-gray-900 dark:text-white">EAN</cite>
                        <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">CEO at EAN</cite>
                    </div>
                </figcaption>
            </figure>


            {/* Our team */}
            <div className="container mx-auto mt-7">

                <hr></hr>
            </div>
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
                    <div className="max-w-2xl">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
                        {/*  <p className="mt-6 text-sm leading-8 text-gray-600">
                            Our team of scientists, agricultural experts, economists, and more make achieving our vision possible.
                            At EAN, we value teamwork and collaboration. Without these, we simply cannot tackle the challenges that
                            lay ahead of us. With over 600 members, the network has team of eight executive member.
                            The executive team works to create a space where every alumnus/alumna feels accepted, valued, and trusted
                            for informed decision-making and support for each other. Units are established based on every member’s specific
                            field of study and occupation. The various units are in this link

                        </p> */}
                        <p className="mt-6 text-sm leading-8 text-gray-600">
                            Our leadership team comprises dedicated individuals who have demonstrated exceptional commitment to the values
                            of EducAid. They bring a wealth of experience, diverse perspectives, and a shared passion for making a difference.
                            Get to know the inspiring individuals who guide our network.

                        </p>
                    </div>
                    <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                        {people.map((person) => (
                            <li key={person.name}>
                                <div className="flex items-center gap-x-6">
                                    <img className="h-32 w-32 rounded-full" src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                                        <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <Footerbar />
        </div>
    )
}

export default Team;