import Footerbar from "../Footerbar/Footerbar";
import Topbar from "../Topbar/Topbar";
import { UserGroupIcon, BookOpenIcon, SparklesIcon, UsersIcon, AcademicCapIcon, GlobeAltIcon } from '@heroicons/react/24/solid';
import { Avatar, Blockquote } from 'flowbite-react';
import React, { useState, useEffect } from 'react';
import './About.css'

const links = [
    { name: 'Open roles', href: '#' },
    { name: 'Internship program', href: '#' },
    { name: 'Our values', href: '#' },
    { name: 'Meet our leadership', href: '#' },
]
const stats = [
    { name: 'Offices worldwide', value: '12' },
    { name: 'Full-time colleagues', value: '300+' },
    { name: 'Hours per week', value: '40' },
    { name: 'Paid time off', value: 'Unlimited' },
]

const values = [
    {
        title: 'Networking',
        description: 'We believe in the power of network and the strength that comes from supporting one another. EAN is a space where shared experiences and collective wisdom are celebrated..',
    },
    {
        title: 'Empowerment',
        description: 'EAN is committed to empowering alumni to reach their full potential. Through mentorship, skill development, and resource-sharing, we strive to uplift each member of our community.',
    },
    {
        title: 'Education',
        description:
            'We value the transformative power of education. EAN encourages a lifelong commitment to learning and believes in the ripple effect that knowledge can have on individuals and communities.',
    },
    {
        title: 'Inclusivity',
        description: 'We embrace diversity and inclusivity. EAN is a space where every alumni voice is heard and respected, regardless of background, identity, or career path.',
    },
    {
        title: 'Social Responsibility',
        description:
            'We are committed to giving back to society by engaging in projects and initiatives that address social issues and contribute to positive change.',
    },
];

const renderIcon = (title) => {
    switch (title.toLowerCase()) {
        case 'social responsibility':
            return <BookOpenIcon className="h-8 w-8 text-blue-500 mb-2" />;
        case 'empowerment':
            return <SparklesIcon className="h-8 w-8 text-yellow-500 mb-2" />;
        case 'inclusivity':
            return <UsersIcon className="h-8 w-8 text-green-500 mb-2" />;
        case 'networking':
            return <GlobeAltIcon className="h-8 w-8 text-purple-500 mb-2" />;
        case 'education':
            return <AcademicCapIcon className="h-8 w-8 text-red-500 mb-2" />;
        default:
            return null;
    }
};


const people = [
    {
        name: 'Ibrahim Bai Bundu',
        role: 'President',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Gribrilla M Kamara',
        role: 'Vice - president',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Gladys Mansaray',
        role: 'Secretary General',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Lawrence Conteh',
        role: 'P.R.O',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Abubakarr Kargbo',
        role: 'Organising Secretary',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Ibrahim Tholley',
        role: 'Financial Secretary',
        imageUrl: 'img/profile.jpg',
    },
    {
        name: 'Baimba S . Kamara',
        role: 'Treasurer',
        imageUrl: 'img/profile.jpg',
    },
    // More people...
]

const features = [
    { name: 'Target 1', description: 'Building a lifelong relationship among alumni members and EducAid management' },
    { name: 'Target 2', description: 'Register the network and open a network’s bank account' },
    { name: 'Target 3', description: 'Conference, general elections and adoption of the EAN constitution' },
    { name: 'Target 4', description: 'Establish units for the alumni to support each other’s development' },

]




const About = () => {

    const images = [
        'img/relasionship.avif',
        'img/bank.avif',
        'img/conference.avif',
        'img/devellopement.avif',
    ];

    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImage((prev) => (prev + 1) % images.length);
        }, 5000); // Change d'image toutes les 5 secondes, ajustez selon vos besoins

        return () => clearInterval(interval);
    }, [images.length]);


    return (
        <div>
            <Topbar />

            {/* about us */}
            <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
                <img
                    src="img/about.avif"
                    alt="about"
                    className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
                />
                <div
                    className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div
                    className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                    aria-hidden="true"
                >
                    <div
                        className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">About us</h2>
                        {/*  <p className="mt-6 text-lg leading-8 text-gray-300">
                            EducAid Alumni Network (EAN) aims to develop and protect EducAid in a sustainable manner for
                            the benefit of the EducAid students and the entire Alumni membership. It maintains a lifelong
                            relationship with alumni through events, reunions, mentorships, lifelong learning opportunities,
                            service programs by informing, engaging and inspiring alumni to stay connected to their alma mater,
                            and support the vision of EducAid Sierra Leone.
                        </p> */}
                        <p className="mt-6 text-lg leading-8 text-gray-300">
                            Welcome to the EducAid Alumni Network (EAN), a vibrant network of individuals who share a common
                            bond forged through education at EducAid. EAN serves as a platform for former students to connect,
                            collaborate, and contribute to the continued success of EducAid and its graduates. Our alumni are
                            the heartbeat of our network, and together, we strive to make a lasting impact on education,
                            empowerment, and nation development.
                        </p>
                    </div>
                    <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
                        <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                            {links.map((link) => (
                                <a key={link.name} href={link.href}>
                                    {link.name} <span aria-hidden="true">&rarr;</span>
                                </a>
                            ))}
                        </div>
                        <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
                            {stats.map((stat) => (
                                <div key={stat.name} className="flex flex-col-reverse">
                                    <dt className="text-base leading-7 text-gray-300">{stat.name}</dt>
                                    <dd className="text-2xl font-bold leading-9 tracking-tight text-white">{stat.value}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>

            {/* Our values */}
            <div className="bg-gray-100 py-16 sm:py-24">
                <div className="container mx-auto max-w-7xl px-6 lg:px-8">
                    <h2 className="text-4xl font-bold text-gray-800 mb-8">Our Core Values</h2>
                    <h2 className=" font-bold text-gray-400 mb-8">In everything we do, we personify and promote the 5 core values:</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {values.map((value, index) => (
                            <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                                {renderIcon(value.title)}
                                <h3 className="text-xl font-semibold mb-4">{value.title}</h3>
                                <p className="text-gray-700 dark:text-gray-400">{value.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>


            {/* our mission */}
            {/*   <div className="bg-white">
                <div className="mx-auto max-w-7xl py-12 sm:px-6 sm:py-32 lg:px-8">
                    <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                        <svg
                            viewBox="0 0 1024 1024"
                            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                            aria-hidden="true"
                        >
                            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                            <defs>
                                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                    <stop stopColor="#7775D6" />
                                    <stop offset={1} stopColor="#E935C1" />
                                </radialGradient>
                            </defs>
                        </svg>
                        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                Boost your productivity.
                                <br />
                                Start using our app today.
                            </h2>
                            <p className="mt-6 text-lg leading-8 text-gray-300">
                                Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                <a
                                    href="#"
                                    className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                >
                                    Get started
                                </a>
                                <a href="#" className="text-sm font-semibold leading-6 text-white">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                        <div className="relative mt-16 h-80 lg:mt-8">
                            <img
                                className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
                                src="img/img1.avif"
                                alt="App screenshot"
                                width={1824}
                                height={1080}
                            />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* our history */}
            <div className="bg-white">
                <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our History</h2>
                        {/* <p className="mt-4 text-gray-500">
                            After years of work together through the EducAid Alumni Network Retrievers’ group (Interim Executive)
                            the substantive network has been formed. In 2021, the Interim Executive and alumni partnered with all
                            alumni working with EducAid to ensure the following targets are achieved by 2023
                        </p> */}
                        <p className="mt-4 text-gray-500">
                            EducAid Alumni Network was established in 2012 by a group of passionate graduates who recognized the value of
                            staying connected beyond the classroom. Over the years, the network has grown, attracting alumni from various
                            fields and regions. Our history is a testament to the enduring impact of EducAid's educational philosophy, and
                            we continue to build upon this legacy by creating a vibrant and engaged network of alumni dedicated to making a
                            difference in the world.
                        </p>

                        <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                            {features.map((feature) => (
                                <div key={feature.name} className="border-t border-gray-200 pt-4">
                                    <dt className="font-medium text-gray-900">{feature.name}</dt>
                                    <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                    <div className="image-carousel">
                        {images.map((src, index) => (
                            <img
                                key={index}
                                src={src}
                                alt={`Image ${index + 1}`}
                                className={`image ${index === currentImage ? 'visible' : ''}`}
                            />
                        ))}
                    </div>
                </div>
            </div>


            {/* our vission*/}
            <div className="bg-white">
                <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                    <div>
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Vision</h2>
                        {/* <p className="mt-4 text-gray-500">
                            After years of work together through the EducAid Alumni Network Retrievers’ group (Interim Executive)
                            the substantive network has been formed. In 2021, the Interim Executive and alumni partnered with all
                            alumni working with EducAid to ensure the following targets are achieved by 2023
                        </p> */}
                        <p className="mt-4 text-gray-500">
                            EducAid Alumni Network envisions a world where every graduate is equipped with the tools and support needed to excel
                            in their chosen paths. We aspire to build a global network of empowered individuals who are catalysts for positive change,
                        </p>

                    </div>

                </div>
            </div>


            {/* Where are we now  */}
            {/*  <figure className="mx-auto max-w-screen-xl text-center">
                <h2 className="text-4xl font-bold text-gray-400 mb-8">Where are we now ?!</h2>
                <svg
                    className="mx-auto mb-3 h-10 w-10 text-gray-400 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 18 14"
                >
                    <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                </svg>
                <Blockquote>
                    <p className="text-xl font-medium italic text-gray-500 dark:text-white">
                        EAN M & E committee is currently working on a kobo toolbox survey to gather information about the alumni's
                        personal, educational, and career pathways, including their majors in college, employment status, and alumni
                        growth. These data would be used to support EAN members and other organisations/institutions to develop their
                        values focused work and work on employability through EAN Skills Bootcamps. EAN Employability Skills Bootcamps
                        - The network aim to address the soft skill gap its members and other organisations/institutions’ might be facing
                        before starting their career. Through this project, alumni can build their CV and digital portfolio, whilst learning
                        crucial soft skills to improve their employability. Employers may get the opportunity to network with us while accessing
                        fresh ideas and talent.
                    </p>
                </Blockquote>
                <figcaption className="mt-6 flex items-center justify-center space-x-3">
                    <Avatar rounded size="xs" img="img/logo.png" alt="profile picture" />
                    <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                        <cite className="pr-3 font-medium text-gray-900 dark:text-white">EAN</cite>
                        <cite className="pl-3 text-sm text-gray-500 dark:text-gray-400">CEO at EAN</cite>
                    </div>
                </figcaption>
            </figure>
 */}

            {/* Our team */}
            {/*         <div className="container mx-auto mt-7">

                <hr></hr>
            </div>
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
                    <div className="max-w-2xl">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Meet our leadership</h2>
                        <p className="mt-6 text-sm leading-8 text-gray-600">
                            Our team of scientists, agricultural experts, economists, and more make achieving our vision possible.
                            At EAN, we value teamwork and collaboration. Without these, we simply cannot tackle the challenges that
                            lay ahead of us. With over 600 members, the network has team of eight executive member.
                            The executive team works to create a space where every alumnus/alumna feels accepted, valued, and trusted
                            for informed decision-making and support for each other. Units are established based on every member’s specific
                            field of study and occupation. The various units are in this link

                        </p>
                    </div>
                    <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
                        {people.map((person) => (
                            <li key={person.name}>
                                <div className="flex items-center gap-x-6">
                                    <img className="h-32 w-32 rounded-full" src={person.imageUrl} alt="" />
                                    <div>
                                        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">{person.name}</h3>
                                        <p className="text-sm font-semibold leading-6 text-indigo-600">{person.role}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div> */}

            <Footerbar />
        </div>
    )
}

export default About;